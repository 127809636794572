import React, {useState} from "react";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc'
import { toast } from 'react-toastify';
import API from '../utils/api';


function Image(props) {
  console.log('Render Image', props.image.id, props.image.live);
  dayjs.extend(relativeTime)
  dayjs.extend(utc);

  const [caption, setCaption] = useState(props.image.caption || '');
  const [url, setUrl] = useState(props.image.url || '');
  const [isChanged, setIsChanged] = useState(false);

  const publishOldReddit = async () => {
    console.log('Publishing to old reddit');
    const toastMsg = `Pushing to old.reddit.com/r/${props.image.subreddit}`;
    const toastId = toast(toastMsg, {autoClose: false});

    const promise = await API.post(`/images/${props.image.id}/publish_old_reddit/`, props.image);
    if (promise.status === 200) {
      console.log(promise.data);
      toast.update(toastId, {
        render: `${toastMsg}: Done!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000
      });
    }
    return promise;
  };

  const publishNewReddit = async () => {
    console.log('Publishing to new reddit');
    const toastMsg = `Pushing to new.reddit.com/r/${props.image.subreddit}`;
    const toastId = toast(toastMsg, {autoClose: false});

    const promise = await API.post(`/images/${props.image.id}/publish_new_reddit/`, props.image);
    if (promise.status === 200) {
      console.log(promise.data);
      toast.update(toastId, {
        render: `${toastMsg}: Done!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000
      });
    }
    return promise;
  };

  const publishImageMeta = async () => {
    console.log('Publishing image meta');
    const promise = await API.post(`/images/${props.image.id}/publish_meta/`, props.image);
    const toastMsg = `Updating image metadata`;
    const toastId = toast(toastMsg, {autoClose: false});

    if (promise.status === 200) {
      console.log(promise.data);
      props.handlePublishResponse(promise.data, props.index);
      toast.update(toastId, {
        render: `${toastMsg}: Done!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000
      });
      setIsChanged(false);
    }
    return promise;
  }
  const updateCaption = (e) => {
    setCaption(e.target.value);
  };

  const updateUrl = (e) => {
    setUrl(e.target.value);
  };

  const updateImage = async (e) => {
    let updateFields = [];
    if (props.image.caption !== caption) { updateFields.push('Caption'); }
    if (props.image.url !== url) { updateFields.push('URL'); }
    if (updateFields.length > 0) {
      const updatedImage = {...props.image, caption: caption, url: url}
      props.handleUpdateImage(updatedImage, props.index, updateFields);
      setIsChanged(true);
    }
  };

  const publish = () => {
    publishOldReddit().then(publishNewReddit).then(publishImageMeta);
  };

  const deleteImage = () => {
    if (window.confirm("Delete this image?")) {
      props.handleDeleteImage(props.image);
    }
  }

  const disabledClasses = (props.image.live && !isChanged) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-dark';

  let containerClasses = 'clearfix p-5 mb-3 bg-brown rounded text-brown-light';
  if(props.image.live) {
    containerClasses += ' border-4 border-yellow';
  }

  let lastPublishedContent = null;
  if (props.image.last_published) {
    lastPublishedContent = (
      <>
      <span className="" title={dayjs(props.image.last_published).local().format('MMM D, YYYY h:mm A')}>{dayjs(dayjs(props.image.last_published)).fromNow()} </span> by
      <span className="text-brown-light"> {props.image.last_published_by}</span>
      </>
    )
  } else {
    lastPublishedContent = '---';
  }

  return (
    <div className={containerClasses}>
      <a href={props.image.image_url} target="_blank" rel="noopener noreferrer">
        <img className="float-left mr-4 h-auto" src={props.image.image_url_thumb} alt="" />
      </a>
      <div className="float-left">
        <div className="mb-4">
          <div className="font-bold uppercase tracking-wide text-xs">Uploaded</div>
          <div className="text-yellow">
            <span title={dayjs(props.image.created).local().format('MMM D, YYYY h:mm A')}>{dayjs(dayjs(props.image.created)).fromNow()} </span>
             by <span className="text-brown-light">{props.image.uploaded_by}</span></div>
        </div>
        <div className="mb-4">
          <div className="font-bold uppercase tracking-wide text-xs">Last Published</div>
          <div className="text-yellow">{lastPublishedContent}</div>
        </div>
        <span className="font-bold uppercase tracking-wide text-xs">Caption</span>
        <textarea className="block w-full h-16 bg-brown-dark p-2" value={caption} onChange={updateCaption} onBlur={updateImage}></textarea>
        <span className="block text-xs mb-3">Optional caption to display below the image only applies to old Reddit.</span>
        <span className="font-bold uppercase tracking-wide text-xs">Link URL</span>
        <input type="url" className="block w-full bg-brown-dark p-2" value={url} onChange={updateUrl} onBlur={updateImage} />
        <span className="block text-xs mb-4">Optional clickable link for the image only applies to new Reddit.</span>
        <button onClick={deleteImage} className={`bg-red-500 text-brown font-bold py-2 px-4 rounded`}>Delete</button>
        <button onClick={publish} disabled={props.image.live} className={`ml-2 bg-yellow text-brown font-bold py-2 px-4 rounded ${disabledClasses}`}>Push To Sidebar</button>
      </div>
    </div>
  );
}

export default Image;
