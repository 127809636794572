import React, {useEffect, useState} from 'react';
import { render } from 'react-dom';
import {Redirect} from 'react-router-dom';
import getSession from '../utils/auth';
import SidebarUpdater from "./sidebar-updater";


function Index(props) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSub, setActiveSub] = useState(null);

  // Load user session.
  useEffect(() => {
    getSession().then((response) => {
      setSession({user: response.data});
      if (response.data.authenticated) {
        setActiveSub(localStorage.getItem('activeSub') || response.data.subreddits[0]);
      }
      setLoading(false);
    });
  }, []);

  const loginContent = (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  );

  let renderContent = null;
  if (loading) {
    renderContent = (
      <h1 className="text-center my-20 text-yellow text-4xl">Loading...</h1>
  );
  } else if (session && session.user.authenticated && activeSub === "Padres") {
    // Allow padres subreddit to render at /
    renderContent = <SidebarUpdater subreddit={activeSub} />;
  } else if (session && session.user.authenticated && activeSub) {
    console.log(`Redirect to subreddit ${activeSub}`);
    renderContent = (
      <Redirect
        to={{
          pathname: `/r/${activeSub}`,
          state: { from: props.location, session: session }
        }}
      />
    );
  } else {
    renderContent = loginContent;
  }

  return (
    <div className="container mx-auto">
      {renderContent}
    </div>
  );
}

export default Index;
