import React from 'react';
import Image from './image';


function ImageList(props) {
  return (
    <div className="mx-auto max-w-3xl">
      <h2 className="text-yellow text-center mb-2">Last {props.images.length} Images</h2>
      {props.images.map((image, index) => (
        <Image
          key={image.id}
          index={index}
          image={image}
          handlePublishResponse={props.handlePublishResponse}
          handleUpdateImage={props.handleUpdateImage}
          handleDeleteImage={props.handleDeleteImage}
        />
      ))}
    </div>
  );
}

export default ImageList;
