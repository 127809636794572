import axios from 'axios';
import Cookies from 'js-cookie';
import SETTINGS from '../settings';

const api = axios.create({
    baseURL: `${SETTINGS.BACKEND_URL}/api/`
  });
  api.defaults.headers.common['X-CSRFToken'] = Cookies.get('csrftoken');
  api.defaults.withCredentials = true;

  export default api;
