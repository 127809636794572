
const SETTINGS = {
    'development': {
        'BACKEND_URL': 'http://localhost:9000',
    },
    'test': {
        'BACKEND_URL': 'http://localhost:9000',
    },
    'production': {
        'BACKEND_URL': '',
    }
};

export default SETTINGS[process.env.NODE_ENV];
