import React, {useRef} from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import API from '../utils/api';


function ImageUploader(props) {
  const s3UploadComponent = useRef(null);
  const s3UploadDir = 'uploads';
  let uploadToastId = null;

  // Get the Signed S3 upload URL
  const getSignedUrl = async (file, callback) => {
    const promise = await API.get("s3url/", {
      params: {
        objectName: file.name,
        contentType: file.type,
        dir: s3UploadDir,
      }
    });
    if (promise.status === 200) {
      callback(promise.data);
    } else {
      console.error(promise.data);
    }
  };

  // Create a Sidebar Image instance
  const createSidebarImage = async (name, path) => {
    const promise = await API.post("images/",
      {
        name: name,
        path: path,
        subreddit: props.subreddit,
      }
    );
    if(promise.status === 201) {
      console.log(promise.data);
      props.onUploadImage(promise.data);
    }
    toast.done(uploadToastId);
  };

  const onUploadProgress = (percent, message, file) => {
    console.log(file);
    const dec = percent / 100;
    console.log('Upload progress: ' + percent + '% ' + dec + ' ' + message);

    if(uploadToastId === null) {
      uploadToastId = toast("Uploading image", {progress: dec, type: toast.TYPE.INFO});
    } else {
      toast.update(uploadToastId, {progress: dec});
    }

  };

  const onUploadFinish = (signResult, file) => {
    console.log(signResult);
    s3UploadComponent.current.clear();
    createSidebarImage(signResult.name, signResult.path);
  };

  const onUploadError = (message, file) => {
    console.log("Upload error: " + message);
  };

  return (
    <div className="text-center m-10">
      <h2 className="text-yellow mb-2">Upload Image</h2>
      <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        signingUrlMethod="GET"
        onProgress={onUploadProgress}
        onError={onUploadError}
        onFinish={onUploadFinish}
        accept="image/*"
        signingUrlWithCredentials={true}
        autoUpload={true}
        className="p-5 rounded bg-yellow text-brown"
        ref={s3UploadComponent}
      />
    </div>
  );
}

export default ImageUploader;
