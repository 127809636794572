import React, {useEffect} from 'react';
import SETTINGS from '../settings';

function Login(props) {

  useEffect(() => {
    document.title = 'Login';
  }, []);

  return (
      <a className="w-64 mt-12 text-center font-bold text-2xl block mx-auto p-4 bg-yellow rounded text-brown hover:bg-yellow-dark" href={`${SETTINGS.BACKEND_URL}/login/reddit/`}>Login With Reddit</a>
  )
}

export default Login;
