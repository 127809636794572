import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Index from "./components/index";
import Login from "./components/login";
import SidebarUpdater from "./components/sidebar-updater";

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Route path="/" exact component={Index} />
          <Route path="/login" component={Login} />
          <Route path="/r/:subreddit" component={SidebarUpdater} />
        </Router>
        <ToastContainer closeButton={false} />
      </>
    );
  }
}

export default App;