import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import {Redirect} from 'react-router-dom';
import ImageUploader from './image-uploader';
import ImageList from './image-list';
import getSession from '../utils/auth';
import API from '../utils/api';


function SidebarUpdater(props) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  console.log(props.subreddit);
  const [activeSub, setActiveSub] = useState(props.subreddit || props.match.params.subreddit);
  const [unsupportedSub, setUnsupportedSub] = useState(false);

  useEffect(() => {
    // If there is already a known session via a router redirect then load images.
    // otherwise fetch the user session.
    const locationState = (props.location) ? props.location.state : null;
    if (locationState && locationState.session && locationState.session.user.authenticated) {
      setSession(locationState.session);
      loadImages();
    } else {
      getSession().then((response) => {
        setSession({user: response.data});
        if (response.data.authenticated) {
          if (!response.data.subreddits.includes(activeSub)) {
            setUnsupportedSub(true);
            setLoading(false);
          } else {
            loadImages();
          }
        } else {
          setLoading(false);
        }
      });
    }
    localStorage.setItem('activeSub', activeSub);
    document.title = `/r/${activeSub} Sidebar Image Updater`;
  }, []);

  // Load image list
  const loadImages = async () => {
    console.log('activeSub', activeSub);
    const promise = await API.get("images/", {
      params: {
        limit: 10,
        subreddit: activeSub,
      }
    });

    if (promise.status === 200) {
      setImages(promise.data.results);
    }
    setLoading(false);
  };

  const onUploadImage = (image) => {
    setImages([image].concat(images));
  };

  const handlePublishResponse = (data, index) => {
    const updateImages = images.map(image => {
      image.live = false;
      return image;
    });
    updateImages[index] = data;
    setImages(updateImages);
  };

  const handleUpdateImage = async (image, index, updateFields) => {
    try {
      const promise = await API.put(`images/${image.id}/`, image);
      if (promise.status === 200) {
        const updateImages = [...images];
        updateImages[index] = promise.data;
        setImages(updateImages);
        toast(`Updated ${updateFields.join('&')}`, {type: toast.TYPE.SUCCESS});
      }
    } catch(err) {
      console.log(err);
      console.log(err.response.data);
      if (err.response.status === 400) {
        for (let field in err.response.data) {
          for (let i = 0; i < err.response.data[field].length; i++) {
            toast(`${field}: ${err.response.data[field][i]}`, {type: toast.TYPE.ERROR});
          }
        }
      }
    }
  };

  const handleDeleteImage = async (image) => {
    try {
      const promise = await API.delete(`images/${image.id}/`);
      if (promise.status === 204) {
        const updateImages = images.filter(i => i.id !== image.id);
        setImages(updateImages);
        toast("Deleted Image", {type: toast.TYPE.SUCCESS});
      }
    } catch(err) {
      console.log(err);
      console.log(err.response.data);
      if (err.response.status === 400) {
        for (let field in err.response.data) {
          for (let i = 0; i < err.response.data[field].length; i++) {
            toast(`${field}: ${err.response.data[field][i]}`, {type: toast.TYPE.ERROR});
          }
        }
      }
    }
  };

  const content = (
    <>
      <a className="w-32 h-auto block mx-auto mt-8" href={`https://www.reddit.com/r/${activeSub}`} target="_blank" rel="noopener noreferrer">
        <img src={process.env.PUBLIC_URL + '/imgs/sd.png'} alt={`Open /r/${activeSub} in a new tab.`} style={{maxWidth: '8rem'}} />
      </a>
      <h1 className="text-2xl text-center text-yellow">/r/{activeSub} Sidebar Image Updater</h1>
      <ImageUploader subreddit={activeSub} onUploadImage={onUploadImage} />
      <ImageList
        images={images}
        handlePublishResponse={handlePublishResponse}
        handleUpdateImage={handleUpdateImage}
        handleDeleteImage={handleDeleteImage}
      />
    </>
  );

  const loginContent = (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  );

  const unsupportedSubreddit = (
    <div className="bg-red-500 text-white w-3/6 mx-auto mt-12 p-8 rounded">
      <p>/r/{activeSub} is either not a supported subreddit or you are not a moderator of this subreddit.
      If you wish to enable support for your subreddit please contact /u/High2plus3.</p>
    </div>
  );

  let renderContent = null;
  if (loading) {
    renderContent = (
      <h1 className="text-center my-20 text-yellow text-4xl">Loading...</h1>
    );
  } else if (session && session.user.authenticated && unsupportedSub) {
    renderContent = unsupportedSubreddit;
    localStorage.removeItem('activeSub');
  } else if (session && session.user.authenticated) {
    renderContent = content;
  } else {
    renderContent = loginContent;
  }

  return (
    <div className="container mx-auto">
      {renderContent}
    </div>
  );
}

export default SidebarUpdater;
